import "core-js/modules/es.array.push.js";
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue";
import _imports_0 from '@/assets/home/home.png';
import _imports_1 from '@/assets/home/home-selected.png';
import _imports_2 from '@/assets/home/activity.png';
import _imports_3 from '@/assets/home/activity-selected.png';
import _imports_4 from '@/assets/home/promotion-selected.png';
import _imports_5 from '@/assets/home/wallet.png';
import _imports_6 from '@/assets/home/wallet-selected.png';
import _imports_7 from '@/assets/home/account.png';
import _imports_8 from '@/assets/home/account-selected.png';
const _hoisted_1 = {
  class: "tabbar__container"
};
const _hoisted_2 = {
  key: 0,
  src: _imports_0
};
const _hoisted_3 = {
  key: 1,
  src: _imports_1
};
const _hoisted_4 = {
  key: 0,
  src: _imports_2
};
const _hoisted_5 = {
  key: 1,
  src: _imports_3
};
const _hoisted_6 = {
  key: 0,
  src: _imports_5
};
const _hoisted_7 = {
  key: 1,
  src: _imports_6
};
const _hoisted_8 = {
  key: 0,
  src: _imports_7
};
const _hoisted_9 = {
  key: 1,
  src: _imports_8
};
import { useRouter } from "vue-router";
import { getToken } from "@/utils/auth";
export default {
  __name: 'BottomTab',
  props: ["id"],
  setup(__props) {
    const props = __props;
    const router = useRouter();
    const changeTab = name => {
      if (!getToken()) {
        router.push({
          name: "login"
        });
      } else {
        router.push({
          name
        });
      }
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "tabbar__container-item",
        onClick: _cache[0] || (_cache[0] = $event => changeTab('home'))
      }, [props.id !== 0 ? (_openBlock(), _createElementBlock("img", _hoisted_2)) : (_openBlock(), _createElementBlock("img", _hoisted_3)), _createElementVNode("span", {
        class: _normalizeClass({
          active: props.id === 0
        })
      }, "Home", 2)]), _createElementVNode("div", {
        class: "tabbar__container-item",
        onClick: _cache[1] || (_cache[1] = $event => changeTab('activity'))
      }, [props.id !== 1 ? (_openBlock(), _createElementBlock("img", _hoisted_4)) : (_openBlock(), _createElementBlock("img", _hoisted_5)), _createElementVNode("span", {
        class: _normalizeClass({
          active: props.id === 1
        })
      }, "Activity", 2)]), _createElementVNode("div", {
        class: "tabbar__container-item",
        onClick: _cache[2] || (_cache[2] = $event => changeTab('promotion'))
      }, [_cache[5] || (_cache[5] = _createElementVNode("img", {
        src: _imports_4
      }, null, -1)), _createElementVNode("span", {
        class: _normalizeClass({
          active: props.id === 2
        })
      }, "Promotion", 2)]), _createElementVNode("div", {
        class: "tabbar__container-item",
        onClick: _cache[3] || (_cache[3] = $event => changeTab('wallet'))
      }, [props.id !== 3 ? (_openBlock(), _createElementBlock("img", _hoisted_6)) : (_openBlock(), _createElementBlock("img", _hoisted_7)), _createElementVNode("span", {
        class: _normalizeClass({
          active: props.id === 3
        })
      }, "Wallet", 2)]), _createElementVNode("div", {
        class: "tabbar__container-item",
        onClick: _cache[4] || (_cache[4] = $event => changeTab('account'))
      }, [props.id !== 4 ? (_openBlock(), _createElementBlock("img", _hoisted_8)) : (_openBlock(), _createElementBlock("img", _hoisted_9)), _createElementVNode("span", {
        class: _normalizeClass({
          active: props.id === 4
        })
      }, "Account", 2)])]);
    };
  }
};