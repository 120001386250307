import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "start-page"
};
import { ref, onMounted } from "vue";
import { getToken } from "@/utils/auth";
export default {
  __name: 'App',
  setup(__props) {
    const count = ref(3);
    const timer = ref(null);
    // const visible = ref("hide");
    const loadingPic = () => {
      if (count.value > 0) {
        timer.value = setInterval(() => {
          count.value--;
        }, 1000);
      } else {
        clearInterval(timer.value);
        timer.value = null;
      }
    };
    // const showAlert = () => {
    //   if (visible.value == "hide") {
    //     visible.value = "show";
    //     window.$chatwoot.toggleBubbleVisibility(visible.value);
    //   }else{
    //     visible.value = "hide";
    //     window.$chatwoot.toggleBubbleVisibility(visible.value);
    //   }
    // };
    onMounted(() => {
      loadingPic();
      window.addEventListener("chatwoot:ready", function () {
        window.$chatwoot.toggleBubbleVisibility("hide");
      });
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view), !_unref(getToken)() && count.value > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1)) : _createCommentVNode("", true)], 64);
    };
  }
};